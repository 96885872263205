

export default {
  name: "message",
  props: {
    id: String,
    icon: String,
    bgIcon: String,
    title: String,
    subtitle: String,
	info: Object,
	hiddenButtons: {
		type: Boolean,
		default: false
	}
  },
};
